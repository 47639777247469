import request from '@/utils/request';

// 获取专题目录
export const subjectFolder = () => request.get(`study/subject/folder/tree`)

// 获取专题列表
export const subjectList = params => request.post(`study/subject/list`, params)

// 获取专题详情
export const subjectDetail = id => request.get(`study/subject/${id}/detail`)

// 专题推荐
export const subjectRecommend = () => request.get(`study/subject/recommend`)

// 专题开始学习
export const subjectStart = id => request.post(`study/subject/start`, {id: id})